import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { maxWidth } from './Layout'

import TextButtonLink from './TextButtonLink'
import SvgButtonLink from './SvgButtonLink'
import Linkedin from '../images/linkedin.svg'
import Email from '../images/email.svg'

import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.background.default,
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
    width: '100%',
    border: 'calc(50vw - ' + maxWidth/2 + 'px) solid ' + theme.palette.background.default,
    borderTopStyle: 'none',
    borderBottomStyle: 'none',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  copyright: {
    display: 'block',
    margin: '0 16px 0 0',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0 0 0',
    },
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.root}>
      <Typography variant='body2' className={classes.copyright}>
        © {new Date().getFullYear()} Jörg Zeipelt
      </Typography>
      <div>
        <TextButtonLink href='/impressum'>Impressum</TextButtonLink>
        <TextButtonLink href='/datenschutz'>Datenschutz</TextButtonLink>
      </div>
      <div>
        <SvgButtonLink href='mailto:mail@joerg-zeipelt.de'>
          <Email />
        </SvgButtonLink>
        <SvgButtonLink href='https://www.linkedin.com/in/jzeipelt/'>
          <Linkedin />
        </SvgButtonLink>
      </div>
    </footer>
)}

export default Footer
