import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { SvgIcon } from '@material-ui/core'

import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    width: 48,
    height: 48,
  },
}))

const SvgButtonLink = ({ children, color='primary', href }) => {
  const classes = useStyles()
  return (
    <a className={classes.root} href={href}>
      <IconButton color={color}>
        <SvgIcon color={color}>
          {children}
        </SvgIcon>
      </IconButton>
    </a>
)}

export default SvgButtonLink
