import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"

import React from 'react'

import { lightTheme, darkTheme, lightGreenTheme, darkGreenTheme } from '../theme'
import Header from "./LayoutHeader"
import Main from "./LayoutMain"
import Footer from "./LayoutFooter"
import Seo from "./Seo"

export const maxWidth = 960

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
    background: theme.palette.background.paper,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}))

const MyLink = function(props) {
  return (props?.href.charAt(0) === "/")?<Link to={props.href}>{props.children}</Link>:<a href={props.href}>{props.children}</a>
}

const components = {
  a: MyLink
}

const Layout = ({ children, title, description }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  const useDarkMode = useMediaPredicate("(prefers-color-scheme: dark)")
  const green = title === "Grün" ? true : false

  const theme = useDarkMode & green ? darkGreenTheme 
    : useDarkMode & !green ? darkTheme
    : !useDarkMode & green ? lightGreenTheme
    : lightTheme

  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={title} description={description} />
      <MDXProvider components={components}>
        <div className={classes.root}>
          <Header siteTitle={data.site.siteMetadata?.title} />
          <Main>{children}</Main>
          <Footer />
        </div>
      </MDXProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node
}

export default Layout
