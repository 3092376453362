import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Link } from "gatsby"

import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    textDecorationLine: 'none',
  },
}))

const TextButtonLink = ({ children, color='secondary', href, size }) => {
  const classes = useStyles()
  return (
    <Link to={href} className={classes.root}>
      <Button variant='text' color={color} size={size}>
        {children}
      </Button>
     </Link>
)}

export default TextButtonLink
