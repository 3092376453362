import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

const overrides = {
  palette: {
    primary: {
      main: '#F2A9A9',
    },
    secondary: {
      main: '#A9CED1',
    },
    action: {
      hoverOpacity: 0.16,
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", sans-serif',
    button: {
      textTransform: 'none',
    },
  },
}

const darkThemeTemp = createTheme({
  ...overrides,
  palette: {
    ...overrides.palette,
    type: 'dark',
    background: {
      paper: '#111',
      default: '#000',
    },
  },
})

const lightThemeTemp = createTheme({
  ...overrides,
  palette: {
    ...overrides.palette,
    type: 'light',
  },
})

const darkGreenThemeTemp = createTheme({
  ...overrides,
  palette: {
    ...overrides.palette,
    primary: {
      ...overrides.primary,
      main: '#E6007E'
    },
    secondary: {
      ...overrides.secondary,
      main: '#F3E40D'
    },
    type: 'dark',
    background: {
      paper: '#093814',
      default: '#06280F',
    },
  },
})

const lightGreenThemeTemp = createTheme({
  ...overrides,
  palette: {
    ...overrides.palette,
    primary: {
      ...overrides.primary,
      main: '#157b2d'
    },
    secondary: {
      ...overrides.secondary,
      main: '#E6007E'
    },
    type: 'light',
    background: {
      ...overrides.background,
      default: '#dff3d2'
    },
  },
})

export const darkTheme = responsiveFontSizes(darkThemeTemp)
export const lightTheme = responsiveFontSizes(lightThemeTemp)
export const darkGreenTheme = responsiveFontSizes(darkGreenThemeTemp)
export const lightGreenTheme = responsiveFontSizes(lightGreenThemeTemp)
