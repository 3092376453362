import { makeStyles } from '@material-ui/core/styles'
import { maxWidth } from './Layout'

import React from 'react'

const useStyles = makeStyles(theme => ({
  main: {
    background: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    padding: theme.spacing(2),
    width: '100%',
    border: 'calc(50vw - ' + maxWidth/2 + 'px) solid ' + theme.palette.background.paper,
    borderTopStyle: 'none',
    borderBottomStyle: 'none',
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
          color: theme.palette.primary.dark,
          textDecoration: 'underline dotted',
        },
      },
  }
}))

const Main = ({ children} ) => {
  const classes = useStyles()
  return (
    <main className={classes.main}>
      {children}
    </main>
)}

export default Main
